import React, { Fragment, Component } from 'react';
import { BrowserRouter, Switch, Route,withRouter,Redirect } from 'react-router-dom'

import Portfolio from '../Pages/portfolio'

class App extends Component {

  constructor(props, context) {
        super(props, context);
        this.state = {
    } 
   
     

  }


    render() {
      /*   window.googleTranslateElementInit(); */
        return (
            <Fragment>
                
                <BrowserRouter >
             
                        <Route  path="/" component={Portfolio} />
                        {/*   <Redirect to="/" /> */}
                
                    
                </BrowserRouter>
            </Fragment>
        );
    }
}

export default App  
 {/* <Route exact path="/founder" component={ComingSoon} /> */}