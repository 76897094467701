import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Button, Header, Placeholder, Popup,Icon, Card, Image } from 'semantic-ui-react'
import profile from "../../../images/1.jpg";
import logo from "../../../images/logo/logo2.png";
import "./style.scss";
import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
const Portfolio = () => {
  const [data, setData] = React.useState(null)
  const timer = React.useRef()
  return (
    <Fragment>
     
 

      <div className="container">
        <div className="portfolio-top-back-area"></div>
        <div className="row portfolio-top-area">
          <div className="col-lg-5 col-sm-4 col-xs-4 col-4">
          <a  target="_blank" href="https://legalemirates.com/founder"
      > 
            <div className="profile-area-portfolio">
              <center>
                {" "}
           <img src={profile} />
    
              </center>
            </div>
            </a>
          </div>
          <div className="col-lg-7 col-sm-8 col-xs-8 col-8">
            <div className="area-portfolio-top-left">
              <h3>Bader Al Zaabi</h3>
              <h4>Founder & Lawyer </h4>
            </div>
            <div className="area-portfolio-top-left-bottom">
              <p>
                {" "}
                <i class="fa fa-check-circle"></i> Law Firm
              </p>
              <p>
                {" "}
                <i class="fa fa-check-circle"></i>  Advocate Office
              </p>
              <p>
                {" "}
                <i class="fa fa-check-circle"></i>  Legal & Lawyer Services
              </p>
              <p>
                {" "}
                <i class="fa fa-check-circle"></i>  Online Counselling
              </p>
              <p>
                {" "}
                <i class="fa fa-check-circle"></i> Legal Consultation
              </p>
              <p>
                {" "}
                <i class="fa fa-check-circle"></i>   Attorney Meetings
              </p>
            </div>
          </div>
        </div>

        <div className="area-portfolio-middle-content">
          <center>
            <h3>Motivating...Excelling...Winning</h3>
            <p># Law Firm #Dubai</p>
          </center>
        </div>
        <div className="row">
          <div className="col-3 my-col-prt">
            <a href="https://legalemirates.com/" target="_blank">
              <div className="area-portfolio-middle-grid-area">
                <center>
                  {" "}
                  <img src={logo} />
                </center>
              </div>
              </a>
          </div>
          <div className="col-3 my-col-prt">
          <a href="https://legalemirates.com/privacypolicy" target="_blank">
              <div className="area-portfolio-middle-grid-area1">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/16.png")} />
                  <h4 style={{ color: "white" }}>Privacy Policy</h4>
                </center>
              </div>
              </a>
          </div>

          {/* <div className="col-3 my-col-prt">
    <a href="tel:+971505848555">
<div className="area-portfolio-middle-grid-area1">
                        <center>  <img src={require('../../../images/portfolio/1.png')} />
                        <h4 style={{color:'white'}}>Phone Number </h4>
                        </center>
                        </div>
                        </a>
</div> */}
          <div className="col-3 my-col-prt">
            <a href="tel:+97142555111">
              <div className="area-portfolio-middle-grid-area1">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/18.png")} />
                  <h4 style={{ color: "white" }}>Office Call </h4>
                </center>
              </div>
            </a>
          </div>
{/* https://drive.google.com/file/d/1SCD0uOUSRHK09oh1xTWGyCa3zDDKU5Ys/view?usp=sharing 
https://drive.google.com/uc?export=view&id=1SCD0uOUSRHK09oh1xTWGyCa3zDDKU5Ys

*/}
          <div className="col-3 my-col-prt">
             <Popup
      on='click'
      onClose={() => {
        setData(null)
        clearTimeout(timer.current)
      }}
      onOpen={() => {
        setData(null)

        timer.current = setTimeout(() => {
          setData({
            description: "jhmjhkjkm,",
            name: "Asim jkehsfjkds",
            title: "fghfdjgidlfk",
          })
        }, 500)
      }}

      popperDependencies={[!!data]}
      trigger={ 
        <div className="area-portfolio-middle-grid-area1">
          <center>
            {" "}
            <img src={require("../../../images/portfolio/5.png")} />
            <h4 style={{ color: "white" }}>Company Profile</h4>
            {/* founder */}
          </center>
        </div>
   }
      wide
      position='bottom right'
    >
      {data === null ? (
        <Placeholder style={{ minWidth: '200px' }}>
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='short' />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <>
         <Card style={{border:'none'}}>
      <Card.Content extra>
      <a  target="_blank" href="https://drive.google.com/file/d/1HhaPCoImhcd1I449NE-2GjlqseW3HsOT/view?usp=sharing"
      >  
      <div className='ui two buttons'>
         
         <Button  basic color='black'>
         <Icon name='edge' />Company Profile in English
         </Button>
       </div>
       </a>
      </Card.Content>
      <Card.Content extra>
      <a  target="_blank" href="https://drive.google.com/file/d/1Dnu7-oP2xl0xOngzwuWzk4_7fzwVYkJg/view?usp=sharing"
      > 
      <div className='ui two buttons'>
         
         <Button  basic color='black'>
         <Icon name='globe' />Company Profile in Arabic
         </Button>
       </div>
       </a>
      </Card.Content>
    </Card>
        </>
      )}
    </Popup>
           
          </div>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          {/* <div className="col-3 my-col-prt">
<a href="sms:+97142555111">
<div className="area-portfolio-middle-grid-area2">
                        <center>  <img src={require('../../../images/portfolio/4.png')} />
                        <h4 style={{color:'white'}}>SMS </h4>
                        </center>
                        </div>
                        </a>
</div> */}

          <div className="col-3 my-col-prt">
            <ul className="mainMenuWrapshare">
              <li style={{ marginTop: "" }}>
                <div className="area-portfolio-middle-grid-area2">
                  <center>
                    {" "}
                    <img src={require("../../../images/portfolio/8.png")} />
                    <h4 style={{ color: "white" }}>Share </h4>
                  </center>
                </div>
                <ul className="subMenu">
                  <li>
                    <TwitterShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>{" "}
                    <FacebookShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>{" "}
                    <WhatsappShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>{" "}
                    <EmailShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      subject={"Bader al Zaabi"}
                      body={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>{" "}
                    <PinterestShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <PinterestIcon size={32} round />
                    </PinterestShareButton>{" "}
                    <LinkedinShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      summary={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                      source={"https://legalemirates.com/"}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>{" "}
                    <InstapaperShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      description={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <InstapaperIcon size={32} round />
                    </InstapaperShareButton>{" "}
                    <WorkplaceShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <WorkplaceIcon size={32} round />
                    </WorkplaceShareButton>{" "}
                    <LivejournalShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <LivejournalIcon size={32} round />
                    </LivejournalShareButton>{" "}
                    <LineShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <LineIcon size={32} round />
                    </LineShareButton>{" "}
                    <MailruShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <MailruIcon size={32} round />
                    </MailruShareButton>{" "}
                    <PocketShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <PocketIcon size={32} round />
                    </PocketShareButton>{" "}
                    <RedditShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <RedditIcon size={32} round />
                    </RedditShareButton>{" "}
                    <TelegramShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>{" "}
                    <TumblrShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <TumblrIcon size={32} round />
                    </TumblrShareButton>{" "}
                    <ViberShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <ViberIcon size={32} round />
                    </ViberShareButton>{" "}
                    <VKShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <VKIcon size={32} round />
                    </VKShareButton>{" "}
                    <OKShareButton
                      url={"https://baderalzaabi.legalemirates.com"}
                      title={"Bader al Zaabi"}
                      via={
                        "Legal Emirates  Legal Plans  provides access to a national network of over  experienced attorneys. Employees are covered for a wide range of personal legal matters."
                      }
                      className="Demo__some-network__share-button"
                    >
                      <OKIcon size={32} round />
                    </OKShareButton>{" "}
                  </li>
                  {/*                    /* 


OKIcon
OKShareButton











*/}
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-3 my-col-prt">
            <a href="mailto:info@legalemirates.com">
              <div className="area-portfolio-middle-grid-area2">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/3.png")} />
                  <h4 style={{ color: "white" }}>Email </h4>
                </center>
              </div>
            </a>
          </div>

          <div className="col-3 my-col-prt">
            <a href="https://g.page/r/CWT_mAPfa4MLEBA">
              <div className="area-portfolio-middle-grid-area2">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/7.png")} />
                  <h4 style={{ color: "white" }}>Location </h4>
                </center>
              </div>
            </a>
          </div>
          <div className="col-3 my-col-prt">
          <a href="https://legalemirates.com/" target="_blank">
              <div className="area-portfolio-middle-grid-area2">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/2.png")} />
                  <h4 style={{ color: "white" }}>Website  </h4>
                </center>
              </div>
         </a>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-3 my-col-prt">
            <a href="https://www.instagram.com/legalalzaabi/"  target="_blank">
              <div className="area-portfolio-middle-grid-area2">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/11.png")} />
                  <h4 style={{ color: "white", marginTop: "5px" }}>
                    Instagram
                  </h4>
                </center>
              </div>
            </a>
          </div>
          <div className="col-3 my-col-prt">
            <a href="https://twitter.com/LegalAlZaabi"  target="_blank">
              <div className="area-portfolio-middle-grid-area2">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/10.png")} />
                  <h4 style={{ color: "white" }}>Twitter</h4>
                </center>
              </div>
            </a>
          </div>

          <div className="col-3 my-col-prt">
            <a href="https://www.facebook.com/Legal-Emirates-Bader-Al-Zaabi-111584597371288" target="_blank">
              <div className="area-portfolio-middle-grid-area2">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/9.png")} />
                  <h4 style={{ color: "white" }}>Facebook</h4>
                </center>
              </div>
            </a>
          </div>

          <div className="col-3 my-col-prt">
          <a href="https://www.youtube.com/channel/UCsBvfRBxFrgBTezFu3d0KSQ/" target="_blank">
            <div className="area-portfolio-middle-grid-area2">
              <center>
                {" "}
                <img src={require("../../../images/portfolio/14.png")} />
                <h4 style={{ color: "white", marginTop: "5px" }}>You tube </h4>
              </center>
            </div>
            </a>
          </div>
       
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          {/*              <div className="col-3 my-col-prt">
                    <a href="https://www.linkedin.com/in/legal-emirates4/">
                    <div className="area-portfolio-middle-grid-area1">
                        <center>  <img src={require('../../../images/portfolio/12.png')} />
                        <h4 style={{color:'white',marginTop:'5px'}}>LinkedIn</h4>
                        </center>
                        </div>
                        </a>
</div>
<div className="col-3 my-col-prt">
<div className="area-portfolio-middle-grid-area4">
                        <center>  <img src={require('../../../images/portfolio/13.png')} />
                        <h4 style={{color:'white',marginTop:'5px'}}>Pinterest </h4>
                        </center>
                        </div>
</div> */}
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-6 my-col-prt">
            <a href="tel:+97142555111">
              <div className="area-portfolio-middle-grid-area3">
                <center>
                  {" "}
                  <img src={require("../../../images/300-01.jpg")} />
                  <h4 style={{ color: "white" }}>Scan open a file</h4>
                </center>
              </div>
            </a>
          </div>
          <div className="col-6 my-col-prt">
            <a href="https://calendly.com/legal_emirates">
              <div className="area-portfolio-middle-grid-area3">
                <center>
                  {" "}
                  <img src={require("../../../images/portfolio/17.png")} />
                  <h4 style={{ color: "white" }}>Schedule Appointment</h4>
                </center>
              </div>
            </a>
          </div>
        </div>
        <div className="area-portfolio-middle-content" style={{marginTop:'40px'}}>
          <center>
           {/*  <h3 style={{ fontSize: "18px", fontWeight: "500" }}>
              www.legalemirates.com
            </h3> */}
         {/*    <p> Created By : Law Firm</p> */}
            <br />
            <br />
          </center>
        </div>
      </div>

    </Fragment>
  );
};
export default Portfolio;
